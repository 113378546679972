/* eslint-disable no-unused-vars */
<template>
  <div>
    <v-snackbar v-model="snackBar" timeout="5000" top>
      โปรดอย่าลืมกด Complete สำหรับเคสที่ทำเสร็จเรียบร้อยแล้วนะคะ
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackBar = false">
          รับทราบ
        </v-btn>
      </template>
    </v-snackbar>
    <div style="text-align: center; padding-left: 30% !important" width="100%">
      <v-alert
        v-if="responseStatus.alertStatus"
        :type="responseStatus.type"
        width="40%"
        style="z-index: 9999; text-align: left"
        dense
        dismissible
      >
        {{ responseStatus.text }}
      </v-alert>
    </div>
    <div class="text-left">
      <h4>{{ tableName }}</h4>
    </div>
    <div></div>
    <v-row>
      <v-col cols="6" sm="6">
        <v-text-field
          label="Patient's name"
          v-model="filterName"
          hint="Patient's name"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="casesTable">
      <template v-slot:item.caseNo="{ item }">
        <a @click="viewCase(item.caseNo)">{{ item.caseNo }}</a>
        <!-- <span
          v-if="
            item.totalRefinement <= 4 &&
            item.totalRefinement - 1 > item.sequence &&
            item.status == 'Completed'
          "
          style="color: #a9a9a9"
        >
          ( Refinement )
        </span> -->
      </template>
      <template v-slot:item.labMonitoring="{ item }">
        <!--v-btn
          v-if="item.totalStep && item.status != 'completed'"
          @click="updateAligner(item.caseNo)"
          color="purple lighten-4"
        >
          Aligner Monitoring </!--v-btn
        ><br /-->
        <span
          v-if="item.totalRefinement <= 4 && item.totalRefinement - 1 > item.sequence"
          style="color: #a9a9a9"
        >
          ( Refinement {{ item.refCaseNo }}/R{{ item.sequence + 1 }} has started)
        </span>
      </template>
      <template v-slot:item.shippingInformation="{ item }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Shipping Name</v-list-item-title>
            <v-list-item-subtitle>{{
              item.shippingInformation.shippingName
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Tracking No.</v-list-item-title>
            <v-list-item-subtitle>{{
              item.shippingInformation.trackingNo
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Shipping Date</v-list-item-title>
            <v-list-item-subtitle>{{
              item.shippingInformation.shippingDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status }}
        <span v-if="item.status == 'Approved' && item.shippingInformation === undefined"
          >( Under fabrication )</span
        >
        <span v-if="item.status == 'Approved' && item.shippingInformation !== undefined"
          >( Shipped )</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            color="primary"
            v-if="
              item.status == 'Waiting for approve' &&
              (item.sequence == 0 || item.sequence == 3)
            "
            @click="checkout(item.caseNo)"
            >Check out {{ item.selectPackage }}
          </v-btn>
          <v-btn
            color="secondary"
            v-if="item.status == 'Draft' && item.sequence == 0"
            @click="checkout(item.caseNo)"
            >Check out
          </v-btn>

          <!-- <v-btn
            v-if="
              item.totalRefinement < 4 &&
              item.totalRefinement - 1 == item.sequence &&
              isCompleted(
                item.stepToComplete,
                item.stepUpload,
                item.caseDate,
                item.runNo
              ) &&
              item.status == 'Approved'
            "
            @click="refinement(item.caseNo, item.sequence)"
          > -->
          <v-btn
            v-if="
              item.totalRefinement < 4 &&
              item.totalRefinement - 1 == item.sequence &&
              item.status == 'Approved'
            "
            @click="refinement(item.caseNo, item.sequence)"
          >
            new Refinement
          </v-btn>

          <!-- approve dialog -->
          <v-dialog
            v-model="completeDialog"
            persistent
            max-width="520"
            v-if="
              item.totalRefinement <= 4 &&
              item.totalRefinement - 1 == item.sequence &&
              isCompleted(
                item.stepToComplete,
                item.stepUpload,
                item.caseDate,
                item.runNo
              ) &&
              item.status == 'Approved'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <!--v-btn color="primary" dark > Open Dialog </!--v-btn-->

              <v-btn v-bind="attrs" v-on="on" color="success" light>
                Complete Case
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                Confirm to complete case {{ item.caseNo }}?
              </v-card-title>
              <!--v-card-text
                >Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.</!--v-card-text
              -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="completeDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="setCompleteCase(item._id, item.caseNo, item.refCaseNo)"
                >
                  Complete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn
            color="primary"
            class="ma-2"
            dark
            @click="
              openFeedback(item.caseNo, item._id, item, 'normal')
            "
            @closeFeedback="closeFeedback"
 
          >
            <v-badge color="orange" v-if="item.status != 'Draft' && isnewFeedback(item)"> open Feedback </v-badge>
            <v-badge v-else value=""> open Feedback </v-badge>
          </v-btn>



          <!-- approve dialog -->
          <v-dialog
            v-model="approveDialog"
            persistent
            max-width="520"
            v-if="
              item.sequence < 3 &&
              item.sequence > 0 &&
              item.status == 'Waiting for approve'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <!--v-btn color="primary" dark > Open Dialog </!--v-btn-->
              <v-btn color="secondary" v-bind="attrs" v-on="on">Approve proposal </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                Confirm to approve case {{ item.caseNo }}?
              </v-card-title>
              <!--v-card-text
                >Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.</!--v-card-text
              -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="approveDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="approveProposal(item._id, item.caseNo)"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <template
            v-if="item.status == 'Waiting for approve' || item.status == 'Approved'"
          >
            <div>
              <v-dialog width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog[item.caseNo] = true"
                  >
                    Download PDF/Media
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      PDF/Media
                    </v-card-title>

                    <v-card-text class="text-left">
                      <a
                        v-if="item.pdfData.files[0]"
                        @click="downloadFile(item.caseNo, item.pdfData.files[0].name)"
                      >
                        Click to download {{ item.pdfData.files[0].name }}</a
                      ><v-divider></v-divider>
                      <a
                        v-if="item.pdfData.files[1]"
                        @click="downloadFile(item.caseNo, item.pdfData.files[1].name)"
                      >
                        Click to download {{ item.pdfData.files[1].name }}</a
                      ><v-divider></v-divider>

                      <a
                        v-if="item.pdfData.files[1]"
                        @click="downloadMultipleFile(item.caseNo, item.pdfData)"
                      >
                        Click to download all files</a
                      >
                      <v-divider></v-divider>
                      <v-btn @click="playVideo(item.caseNo, item.proposal)"
                        >Play Video</v-btn
                      >
                       <v-divider></v-divider>
                      <v-btn @click="openLink(item.video_link)"
                        >EZCheck</v-btn
                      >
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn color="primary" @click="dialog.value = false"> Close </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </template>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="loadPlayer" max-width="90%" persistent>
      <player-viewer :props="mp4" @closePlayer="closePlayer"></player-viewer>
    </v-dialog>
    <feedback-app
      v-model="feedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="feedbackDialog"
      useBy="DR"
      @closeFeedback="closeFeedback"
      v-if="feedbackCaseId"
    ></feedback-app>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// eslint-disable-next-line no-unused-vars
import { nullOrUndefined, keyExists } from "../../../../plugins/libs.js";
import feedBack from "../../library/feedback/feedback";

//import player from "../../library/video-player/player";
import player from "../../library/video-player/v-player";
import axios from "axios";
export default {
  name: "AllCasesTable",
  props: {
    doctorInfo: {
      type: Object,
    },
    tableName: {
      type: String,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    "feedback-app": feedBack,
    "player-viewer": player,
  },
  data() {
    return {
      loadPlayer: false,
      loadCase: "",
      snackBar: false,
      feedbackCaseNo: "",
      feedbackDialog: false,
      feedbackCaseId: "",
      approveDialog: false,
      completeDialog: false,
      //defaultPrice: 4000,
      responseStatus: { alertStatus: false, type: "", text: "" },
      closeInSec: process.env.VUE_APP_ALERT_DISPLAY * 1000,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      filterName: "",
      doctor: {},
      url: process.env.VUE_APP_LOYALTY_API_URI,
      cases: [],
      casesTable: [],
      options: {},
      dialog: {},
      completedInYear: 0,
      approveCasesInYear: {},
      mp4: [],
      useBy: "DR",

      countInterval:null,

      regDate: {},
      headers: [
        {
          value: "caseNo",
          text: "Case",
          // eslint-disable-next-line no-unused-vars
          // sort: (a, b) => {
          //   if (b.length > a.length) return 1;
          // },
        },
        {
          value: "patient",
          text: "Patient", // this.$t("tables.headings.status")
        },

        {
          value: "selectPackage",
          text: "Package", // this.$t("tables.headings.point")
        },
        {
          value: "status",
          text: "Case Status", // this.$t("tables.headings.point")
        },
        /*{
          value: "paymentStatus",
          text: "Payment Status", // this.$t("tables.headings.point")
        },*/
        {
          value: "shippingInformation",
          text: "Shipping Information", // this.$t("tables.headings.point")
        },
        {
          value: "labMonitoring",
          text: "Aligner Wear Monitoring", // this.$t("tables.headings.point")
        },

        {
          value: "actions",
          text: "Actions", // this.$t("tables.headings.actions")
        },
      ],
    };
  },
   beforeDestroy() {
    clearInterval(this.countInterval);
  },
  mounted() {
    ////console.log(this.doctorInfo);
    //if (typeof this.getInfo.doctor != "undefined") {
    ////console.log(this.getInfo);
    //this.doctor = this.$cookies.get("Profile").doctor[0];
    this.doctor = JSON.parse(localStorage.getItem("Profile")).doctor[0];
    this.listCases();

    this.regDate = this.splitDate(this.doctor.createdAt);
    //this.snackBar = false;

    //console.log(this.regDate);
    let regDateTime = new Date(
      new Date().getFullYear() + "-" + this.regDate.month + "-" + this.regDate.date
    ).getTime();
    ////console.log(regDateTime);
    ////console.log(_cYear);
    //let _caseDate = this.splitDate(caseDate);
    let oneday = 60 * 60 * 24 * 1000;
    let toDate = new Date().getTime();
    //console.log(toDate);
    //console.log((regDateTime - toDate) / oneday);
    if (-10 < (regDateTime - toDate) / oneday > 0) {
      this.snackBar = false; // disable alert by set to false
    }

    this._interval();
  },

  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getInfo: "BFC/getDoctorInfo",
      listAll: "BFC/allCases",
    }),
    /*newFeedback: (item)=>{
        return false;
      }
      */
  },
  methods: {
    ...mapActions({
      allCases: "BFC/listCases",
      setAllCases: "BFC/setAllCases",
    }),

    _interval() {
      console.log("_interval");
      this.countInterval = setInterval(this.listCases, 30000);
    },

    isnewFeedback(item){
      console.log(item)
      //console.log(item.feedback.map(item =>{ return item.read }));
      if( item.feedback.length > 0  ){
          console.log(item.feedback);
          return (item.feedback.map(item =>{ return item.read }).indexOf("DR") == -1);
        }else{
          return true;
        }
    },
    isnewInspectorFeedback(item){
      if( item.inspectorFeedback.length > 0  ){
          return (item.inspectorFeedback.map(item =>{ return item.read }).indexOf("DR") == -1);
        }else{
          return true;
        }
    },
    openLink(url){
        //console.log(caseNo);
        //console.log(url);
        ////console.log(item);
        //console.log("XXXXXXXXXXXXX");
        window.open(url,'_blank');
      },

    playVideo(caseNo, videoList) {
      //console.log(videoList);
      this.loadCase = caseNo;
      try {
        this.mp4 = this.playList(videoList);
        this.loadPlayer = true;
      } catch (err) {
        //console.log("error " + err);
        alert("Can not play video!");
      }
    },
    playList(videoList) {
      let mp4 = [];
      videoList.forEach((item) => {
        mp4.push({
          url: this.url + "/public/cases/" + this.loadCase + "/mp4/" + item.videoFile,
          name: item.name,
        });
      });
      Promise.all(mp4);
      ////console.log(mp4);
      return {
        allowFullScreen: true,
        preload: "auto",
        autoplay: "autoplay",
        sources: mp4,
      };
    },
    closePlayer() {
      //console.log("close dialog player");
      this.loadPlayer = false;
    },

    alertDoctor() {},
    closeInTime(sec) {
      ////console.log("call closeinTime " + sec);
      setTimeout(() => {
        this.responseStatus.alertStatus = false;
      }, sec);
    },
    // async completeCase(caseId) {
    //   //console.log(caseId);
    // },
    splitDate(dt) {
      let splitArr = dt.split("T");
      ////console.log(_regArr);
      let splitDateYear = splitArr[0].split("-");
      // this.regDate.push({ year: _regDateYear[0] });
      // this.regDate.push({ month: _regDateYear[1] });
      // this.regDate.push({ date: _regDateYear[2] });
      return {
        year: splitDateYear[0],
        month: splitDateYear[1],
        date: splitDateYear[2],
      };
    },
    waiting2Complete(caseDate, caseNo) {
      //console.log(caseDate);
      //console.log(caseNo);
      switch (parseInt(new Date().getFullYear()) - parseInt(caseDate.year)) {
        case 0:
          if (keyExists(this.approveCasesInYear, caseNo)) {
            //
            this.approveCasesInYear[caseNo] = 1;
          } else {
            this.approveCasesInYear = {
              ...this.approveCasesInYear,
              caseNo: 1,
            };
          }
          break;
        case 1:
          break;
        default:
          break;
      }
    },

    isCompleted(step, uploaded, caseDate, caseNo) {
      console.log(caseDate + " " + caseNo);
      // //console.log("step");
      // //console.log(step);
      // //console.log(uploaded);
      //let _caseDate = new Date(caseDate).getTime();
      // let cYear = new Date().getFullYear();
      // //console.log(cYear);
      //let toDate = new Date().getTime();
      // //console.log(new Date(caseDate).toLocaleString());
      // //console.log(new Date(this.doctor.createdAt).getFullYear());

      ////console.log(new Date(parseFloat(toDate) - parseFloat(_caseDate)).toISOString());
      // //console.log(
      //   Math.ceil((parseFloat(toDate) - parseFloat(_caseDate)) / (60 * 60 * 24 * 1000))
      // );
      // //console.log(_caseDate);
      // //console.log(toDate);
      //let _cYear = new Date().getFullYear();

      if (step != 0) {
        let totalStep = step.split(",").length;
        ////console.log(totalStep);
        if (uploaded.length == totalStep * 5) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    refinement(caseNo, sequence) {
      this.$router.push("new-refinement/" + caseNo + "/" + (sequence + 1));
    },
    closeFeedback(val) {
      ////console.log("close feedback");
      this.feedbackDialog = val;
      this.feedbackCaseNo = "";
      this.feedbackCaseId = "";
      ////console.log(this.feedbackDialog);
    },
    openFeedback(caseNo, caseId, item,dialog) {
      console.log(item);
      ////console.log(this.feedbackDialog);
      //console.log(this.casesTable.indexOf(item));
      //this.casesTable[this.casesTable.indexOf(item)].feedback = null;
      //console.log(this.casesTable[this.casesTable.indexOf(item)]);
      this.feedbackCaseNo = caseNo;
      this.feedbackCaseId = caseId;
      //this.feedbackDialog = true;
      /*
      if(dialog != 'inspector'){
        this.feedbackDialog = true;
        this.casesTable[this.casesTable.indexOf(item)].feedback = null;
        item.feedback = null;
      }else{
        this.inspectorFeedbackDialog = true;
        }
        */
        if(dialog != 'inspector'){
        this.feedbackDialog = true;
        //Object.assign(item.feedback,{read:"ADMIN"});
        //console.log(item);
        item.feedback.push({read:"DR"})
      }else{
        this.inspectorFeedbackDialog = true;
        //Object.assign(item.inspectorFeedback,{read:"ADMIN"});
        item.inspectorFeedback.push({read:"DR"})
        }
    },
    async listCases() {
      ////console.log(this.doctor);
      try {
        let payload = {
          page: 0,
          limit: 999,
          filter: "",
          doctorId: this.doctor._id,
        };
        ////console.log(payload);
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/listCases",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            ////console.log("listCases");
            ////console.log(response.data.data);
            if (response.data.statusCode == 0) {
              //
              console.log(response.data);
              this.setAllCases(response.data.data);
              this.cases = response.data.data.map((item) => {
                //console.log("item");
                //console.log(item);
                //console.log("--------------------------------");
                let _package = {
                  text: "",
                  value: "",
                  price: 0,
                  packageStatus: "None",
                };
                if (typeof item.package !== "undefined") {
                  let reg = /[0-9\\,]/gi;
                  let packageText = item.package.text.replace(reg, "");
                  item.package.text = packageText;
                  _package = item.package;
                }
                let selectPackage = "";
                if (typeof _package.text !== "undefined") {
                  selectPackage = _package.text;
                }

                let pdfData = {};
                //let file0 = "";
                //let file1 = "";
                if (typeof item.pdfData !== "undefined") {
                  pdfData = item.pdfData;
                } else {
                  pdfData = {
                    files: [
                      {
                        name: "",
                        mimetype: "",
                      },
                      {
                        name: "",
                        mimetype: "",
                      },
                    ],
                  };
                }
                let labUpload = item.labUpload || {};
                ////console.log(item.shippingInformation);
                let shippingInformation = item.shippingInformation || {
                  shippingName: "-",
                  trackingNo: "-",
                  shippingDate: "-",
                };

                ////console.log(shippingInformation);

                let status = "Draft";
                if (typeof item.caseStatus !== "undefined") {
                  status = item.caseStatus.stateStatus;
                }

                let stepUpload = [];
                if (typeof item.stepUpload !== "undefined") {
                  stepUpload = item.stepUpload;
                }

                let feedback = [];
                  //console.log(typeof item.feedback);
                  if (item.feedback !== undefined && item.feedback != null ) {
                    feedback = item.feedback;
                  }


                  let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
                  if (item.inspectorFeedback !== undefined && item.inspectorFeedback != null ) {
                    inspectorFeedback = item.inspectorFeedback;
                  }
                return {
                  _id: item._id,
                  caseDate: item.createdAt,
                  caseNo: item.caseNo,
                  sequence: item.sequence,
                  refCaseNo: item.refCaseNo,
                  video_link: item.video_link || "",
                  patient:
                    nullOrUndefined(item.caseDetail.firstname) +
                    " " +
                    nullOrUndefined(item.caseDetail.lastname),
                  process: "",
                  aligner_wear_log: "",
                  status: status, //item.caseStatus.stateStatus,
                  selectPackage: selectPackage,
                  gender: nullOrUndefined(item.caseDetail.gender),
                  race: nullOrUndefined(item.caseDetail.race),
                  birthdate: nullOrUndefined(item.caseDetail.birthdate),
                  mobile: nullOrUndefined(item.caseDetail.mobile),
                  casePackage: _package,
                  paymentStatus: _package.packageStatus,
                  totalStep: item.totalStep,
                  labUpload: labUpload,
                  shippingInformation: shippingInformation,
                  pdfData: pdfData,
                  proposal: item.proposal,
                  runNo: item.runNo,
                  stepUpload: stepUpload,
                  totalRefinement: item.totalRefinement,
                  feedback: feedback,
                    inspectorFeedback: inspectorFeedback,
                  stepToComplete:
                    typeof item.stepToComplete === "undefined" ? 0 : item.stepToComplete,
                };
              });
              //this.setAllCases(allCases);
              //this.cases = allCases;
              ////console.log("cases");
              console.log(this.cases);
              this.casesTable = this.cases;
            } else {
              // show error
              //console.log("error response");
            }
          });
      } catch (e) {
        //
        //console.log(e);
      }
    },

    downloadFile(caseNo, filename) {
      axios({
        url: this.url + "/public/cases/" + caseNo + "/" + filename,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    downloadMultipleFile(caseNo, PDFFiles) {
      ////console.log(PDFFiles);
      for (let i = 0; i < PDFFiles.files.length; i++) {
        let filename = PDFFiles.files[i].name;
        axios({
          url: this.url + "/public/cases/" + caseNo + "/" + filename,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
    },
    viewCase(caseNo) {
      this.$router.push("/doctor/case/" + caseNo);
    },
    updateAligner(caseNo) {
      this.$router.push("/doctor/update-aligner/" + caseNo);
    },
    checkout(caseNo) {
      ////console.log(caseNo);
      this.$router.push("/doctor/checkout/" + caseNo);
    },
    async approveProposal(caseId, caseNo) {
      ////console.log(this.doctor);
      this.approveDialog = false;
      try {
        let payload = {
          caseId: caseId,
          caseNo: caseNo,
          custId: this.custId,
          case: {
            caseStatus: {
              stateStatus: "Approved",
            },
            proposalStatus: "Approved",
          },
        };
        ////console.log(payload);
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/approveProposal",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            //console.log("listCases");
            ////console.log(response.data.data);
            if (response.data.statusCode == 0 && response.data.nModified > 0) {
              //
              ////console.log(response.data);
              this.responseStatus = {
                alertStatus: true,
                type: "success",
                text: "Approved",
              };
              ////console.log("response list case");
              //console.log(this.responseStatus);
              await this.listCases();
            } else {
              // show error
              ////console.log("error response");
              //console.log(response);
            }
          });
      } catch (e) {
        //
        //console.log(e);
      }
    },
    async setCompleteCase(caseId, caseNo, refCaseNo) {
      this.completeDialog = false;
      try {
        let payload = {
          caseId: caseId,
          caseNo: caseNo,
          custId: this.custId,
          refCaseNo: refCaseNo,

          case: { caseStatus: { stateStatus: "Completed" }, caseCompleted: 1 },
        };
        ////console.log(payload);
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/setCompleteCase",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            ////console.log("complete case");
            ////console.log(response);
            if (response.data.statusCode == 0 && response.data.data.nModified > 0) {
              //
              //console.log(response.data);
              this.responseStatus = {
                alertStatus: true,
                type: "success",
                text: "Set to completed",
              };
              ////console.log("response list case");
              ////console.log(this.responseStatus);
              this.casesTable
                .filter((item) => {
                  return item.refCaseNo == refCaseNo;
                })
                .map((item) => {
                  item.status = "Completed";
                });
              //await this.listCases();
              ////console.log("---------------------------------");
              ////console.log(this.casesTable);
            } else {
              // show error
              ////console.log("error response");
              ////console.log(response);
            }
          });
      } catch (e) {
        //
        //console.log(e);
      }
    },
  },
  watch: {
    responseStatus: {
      handler() {
        ////console.log("responseStatus");
        ////console.log(this.responseStatus);
        if (this.responseStatus.alertStatus === true) {
          this.closeInTime(this.closeInSec);
        }
      },
    },
    filterName: {
      handler(val) {
        ////console.log(val);
        this.casesTable = this.cases
          .filter((item) => {
            ////console.log(item);
            return (
              item.patient.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              item.caseNo.toLowerCase().indexOf(val.toLowerCase()) > -1
            );
          })
          .filter((item) => {
            if (val != "shipped") {
              let filterTable = this.tableName == "All" ? "" : this.tableName;
              return item.status.indexOf(filterTable) > -1;
            } else {
              // shipped cases
              ////console.log(item.shippingInformation);
              return item.shippingInformation.shippingName != "-";
            }
          });
      },
    },
    tableName: {
      handler(val) {
        console.log(val);
        ////console.log("tableName " + val);
        
        this.casesTable = this.cases
          .filter((item) => {
            if (val != "Shipped") {
              let filterTable = val == "All" ? "" : val;
              return item.status.indexOf(filterTable) > -1;
            } else {
              // shipped cases
              ////console.log("shipped filter");
              ////console.log(item.shippingInformation);
              return item.shippingInformation.shippingName != "-";
            }
          })
          .filter((item) => {
            ////console.log("patient filter");
            return (
              item.patient.toLowerCase().indexOf(this.filterName) > -1 ||
              item.caseNo.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1
            );
          });
          
      },
    },
  },
};
</script>
<style scope>
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-size: 0.9em;
}
</style>
